import {
  Alert,
  AlertColor,
  Button,
  Checkbox,
  Divider,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import "./HomepageScreen.scss";
import GilImage from "../../assets/images/cropped-gil1.jpg";
import { useState } from "react";
// @ts-ignore
import CV_pdf from "../../assets/pdf/Gil_CV.pdf";
import emailJs from "@emailjs/browser";

const HomepageScreen: React.FC = () => {
  const theme = useTheme();

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [consentsChecked, setConsentsChecked] = useState(false);

  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    severity?: AlertColor | undefined;
    text?: string;
  }>({
    open: false,
    severity: undefined,
    text: "",
  });

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await emailJs.sendForm(
        "service_8isdack",
        "template_bppwefm",
        e.target,
        process.env.REACT_APP_SERVICE_ID
      );

      setFormData(initialState);

      setSnackbarState({
        open: true,
        severity: "success",
        text: "Inviato con successo",
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        text: "Errore nell'invio dati, riprova",
      });
    } finally {
      setConsentsChecked(false);
    }
  };

  return (
    <div className="home-container">
      <div className="intro-card">
        <div className="title">
          <Typography variant="h2" color="white" fontWeight={500}>
            Dott. Gilberto Laffi
          </Typography>
          <Typography variant="h6" color="white">
            DIABETOLOGO
          </Typography>
        </div>
      </div>

      <div className="second-card">
        <div className="card-description">
          <div style={{ marginBottom: 16 }}>
            <Typography variant="h3" color={theme.palette.primary.main}>
              Il tuo Diabetologo a Bologna
            </Typography>
          </div>
          <Divider
            variant="fullWidth"
            sx={{
              width: "25%",
              backgroundColor: theme.palette.primary.main,
              height: 2,
              marginBottom: 2,
            }}
          />
          <div style={{ marginBottom: 16 }}>
            <Typography variant="subtitle1" style={{ marginBottom: 12 }}>
              Sono un Diabetologo con una formazione approfondita e una
              dedizione alla cura dei pazienti diabetici. Ho conseguito la mia
              laurea presso l’Università degli Studi di Bologna e ho proseguito
              la mia formazione con la specializzazione in Diabetologia e
              Malattie del Ricambio presso l’Università di Parma. Ho partecipato
              e tenuto corsi avanzati nella diabetologia regionale e Nazionale.
            </Typography>
            <Typography variant="subtitle1">
              La mia missione è quella di migliorare la qualità di vita dei
              pazienti affetti da diabete attraverso cure personalizzate e
              soluzioni basate sulle ultime ricerche scientifiche.
            </Typography>
          </div>
          <a href={CV_pdf} rel="noopener noreferrer" target="_blank">
            <Button
              variant="outlined"
              className="button-readme"
              style={{ width: "50%" }}
            >
              Leggi il mio Curriculum
            </Button>
          </a>
        </div>
        <div className="image-card">
          <img
            width="100%"
            height="auto"
            src={GilImage}
            alt="Dott. Gilberto Laffi - Diabetologo a Bologna"
          ></img>
        </div>
      </div>

      <div className="contacts-card">
        <Typography variant="h3" color={theme.palette.primary.main}>
          Contatti
        </Typography>
        <Divider
          variant="middle"
          sx={{
            alignSelf: "center",
            width: "10%",
            backgroundColor: theme.palette.primary.main,
            height: 2,
            marginBottom: 2,
            marginTop: 2,
          }}
        />
        <form
          onSubmit={handleSubmit}
          className="contacts-form"
          style={{ width: "50%" }}
        >
          <TextField
            required
            label="Nome"
            variant="outlined"
            margin="normal"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            inputProps={{ maxLength: 30 }}
          />
          <TextField
            required
            label="Cognome"
            variant="outlined"
            margin="normal"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            inputProps={{ maxLength: 30 }}
          />
          <TextField
            required
            label="Email"
            variant="outlined"
            margin="normal"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            required
            label="Telefono"
            variant="outlined"
            margin="normal"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            inputProps={{ maxLength: 30 }}
          />
          <TextField
            required
            label="Messaggio"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            inputProps={{ maxLength: 400 }}
          />
          <div className="consents">
            <Checkbox
              required
              checked={consentsChecked}
              onChange={(e) => setConsentsChecked(e.target.checked)}
            />
            <Typography variant="body2" color={theme.palette.primary.main}>
              Acconsento al trattamento dei miei dati personali ai sensi della
              Privacy Policy.
            </Typography>
          </div>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            style={{ width: "50%", marginTop: 16 }}
          >
            Invia
          </Button>
        </form>
        <Snackbar
          open={snackbarState.open}
          autoHideDuration={5000}
          onClose={() => {
            setSnackbarState({ open: false, severity: undefined });
          }}
        >
          <Alert
            onClose={() => {
              setSnackbarState({ open: false, severity: undefined });
            }}
            severity={snackbarState.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarState.text}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default HomepageScreen;
